
.page {
  position: absolute;
  left: 0;
  right: 0;
}

.page-enter {
  opacity: 0;
  transform: scale(0.93);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms, transform 100ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 100ms, transform 100ms;
}
